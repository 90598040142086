export default `
  a {
    text-decoration: none !important;
  }

  h1 {
    font-family: Staatliches !important;
    font-size: 80px !important;
  }

  #category {
    font-family: Staatliches !important;
    font-weight: 500 !important;
  }

  #title {
    letter-spacing: 0.4px !important;
    font-size: 22px !important;
    font-size: 1.375rem !important;
    line-height: 1.13636 !important;
  }

  #banner {
    margin: 20px !important;
    height: 800px !important;
  }

  #editor {
    font-size: 16px !important;
    font-size: 1rem !important;
    line-height: 1.75 !important;
  }

  .uk-navbar-container {
    background: #fff !important;
    font-family: Staatliches !important;
  }

  img:hover {
    opacity: 1 !important;
    transition: opacity 0.25s cubic-bezier(0.39, 0.575, 0.565, 1) !important;
  }
  .banner-bg {
    position: absolute !important;
    width: calc(100% - 2.5rem) !important;
    height: 800px !important;
}
`
